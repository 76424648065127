<template>
	<div>
		<CHeader fixed with-subheader light>
			<CToggler
				in-header
				class="ml-3 d-lg-none"
				@click="toggleSidebarMobile()"
			/>
			<CToggler
				in-header
				class="ml-3 d-md-down-none"
				@click="toggleSidebarDesktop()"
			/>
			<CHeaderBrand class="mx-auto d-lg-none" to="/">
				<CIcon name="logo" height="48" alt="Logo" />
			</CHeaderBrand>
			<CHeaderNav class="d-md-down-none mr-auto">
				<CHeaderNavItem class="px-3">
					<CHeaderNavLink to="#">
						{{ name }}
					</CHeaderNavLink>
				</CHeaderNavItem>
			</CHeaderNav>
			<CHeaderNav class="mr-4">
				<TheHeaderDropdownAccnt />
			</CHeaderNav>
			<CSubheader class="px-3">
				<CBreadcrumbRouter class="border-0 mb-0" />
			</CSubheader>
		</CHeader>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="false"
			title="Modal title 2"
			size="lg"
			color="info"
		>
			<p class="h5">
				Install aplikasi HARTA GRATIS.<br />
				Aplikasi ini
				<span class="text-primary">tidak akan memakan ruang hardisk</span>
				di ponsel / komputer Anda<br />
				dan juga masih berfungsi offline!
			</p>
			<template #header>
				<h6 class="modal-title">Install Aplikasi</h6>
				<CButtonClose @click="modal = false" class="text-white" />
			</template>
			<template #footer>
				<CButton @click="dismiss" color="secondary">CANCEL</CButton>
				<CButton @click="install" color="success">INSTALL</CButton>
			</template>
		</CModal>
	</div>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import { mapMutations, mapState } from "vuex";
import Cookies from "js-cookie";
import { basuri } from "@/plugins/utils";

export default {
	name: "TheHeader",
	components: {
		TheHeaderDropdownAccnt,
	},
	computed: {
		...mapState("appinfo", ["name", "username", "role"]),
	},
	data() {
		return {
			deferredPrompt: null,
			modal: false,
		};
	},
	created() {
		window.addEventListener("beforeinstallprompt", (e) => {
			e.preventDefault();
			// Stash the event so it can be triggered later.
			if (Cookies.get("add-to-home-screen") === undefined) {
				//console.log("cookies belum");
				this.deferredPrompt = e;
				this.modal = true;
			} else {
				//console.log("cookies sudah");
			}
		});

		window.addEventListener("appinstalled", () => {
			this.deferredPrompt = null;
			this.modal = false;
		});

		window.addEventListener("online", handleConnection);
		window.addEventListener("offline", handleConnection);

		function handleConnection() {
			if (navigator.onLine) {
				isReachable(basuri).then(function(online) {
					if (online) {
						// handle online status
						console.log("online");
						//this.$toast.warning("Success ! " + datar.message);
					} else {
						this.$toast.warning("Connection ! " + "no connectivity");
						console.log("no connectivity");
					}
				});
			} else {
				// handle offline status
				this.$toast.warning("Connection ! " + "no internet access");
				console.log("offline");
			}
		}

		function isReachable(url) {
			/**
			 * Note: fetch() still "succeeds" for 404s on subdirectories,
			 * which is ok when only testing for domain reachability.
			 *
			 * Example:
			 *   https://google.com/noexist does not throw
			 *   https://noexist.com/noexist does throw
			 */
			return fetch(url, { method: "HEAD", mode: "no-cors" })
				.then(function(resp) {
					return resp && (resp.ok || resp.type === "opaque");
				})
				.catch(function(err) {
					this.$toast.warning("Connection ! " + "test failure");
					console.warn("[conn test failure]:", err);
				});
		}
	},
	methods: {
		...mapMutations("menu", ["toggleSidebarDesktop", "toggleSidebarMobile"]),
		async dismiss() {
			const inFifteenMinutes = new Date(new Date().getTime() + 3 * 60 * 1000);
			Cookies.set("add-to-home-screen", null, { expires: inFifteenMinutes });
			this.deferredPrompt = null;
			this.modal = false;
		},
		async install() {
			this.deferredPrompt.prompt();
		},
	},
};
</script>
