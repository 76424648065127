<template>
	<CDropdown
		inNav
		class="c-header-nav-items"
		placement="bottom-end"
		add-menu-classes="pt-0"
	>
		<template #toggler>
			<CHeaderNavLink>
				<div class="c-avatar">
					<img
						:src="
							role == 'admin' ? 'img/avatars/admin.png' : 'img/avatars/user.png'
						"
						class="c-avatar-img "
					/>
				</div>
			</CHeaderNavLink>
		</template>
		<CDropdownHeader tag="div" class="text-center" color="light">
			<strong>{{ name }} - {{ role.toUpperCase() }}</strong>
		</CDropdownHeader>
		<CDropdownDivider />
		<CDropdownItem @click="logout">
			<CIcon name="cil-lock-locked" /> Logout
		</CDropdownItem>
	</CDropdown>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "TheHeaderDropdownAccnt",
	data() {
		return {
			itemsCount: 42,
		};
	},
	computed: {
		...mapState("appinfo", ["name", "username", "role"]),
	},

	methods: {
		logout() {
			this.setNameToDefault();
			this.$router.push({ name: "Login" });
		},
	},
};
</script>

<style scoped>
.c-icon {
	margin-right: 0.3rem;
}
</style>
