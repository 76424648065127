<template>
	<CSidebar
		fixed
		:minimize="minimize"
		:show="show"
		@update:show="(value) => set(['sidebarShow', value])"
	>
		<CSidebarBrand class="d-md-down-none" to="/">
			<CIcon
				class="c-sidebar-brand-full"
				name="logo"
				size="custom-size"
				:height="35"
				viewBox="0 0 300 65"
			/>
			<CIcon
				class="c-sidebar-brand-minimized"
				name="logo"
				size="custom-size"
				:height="35"
				viewBox="0 0 300 65"
			/>
		</CSidebarBrand>

		<CRenderFunction flat :content-to-render="navMenu()" />
		<CSidebarMinimizer
			class="d-md-down-none"
			@click.native="set(['sidebarMinimize', !minimize])"
		/>
	</CSidebar>
</template>

<script>
import nav from "./_nav";
import { mapMutations, mapState } from "vuex";
import { guard } from "@/plugins/utils";

export default {
	name: "TheSidebar",
	nav,

	computed: {
		...mapState("menu", ["sidebarShow", "sidebarMinimize"]),
		//...mapState('appinfo',['permissions']),
		show() {
			return this.sidebarShow;
		},
		minimize() {
			return this.sidebarMinimize;
		},
	},

	mounted() {
		//this.navMenu()
	},
	methods: {
		...mapMutations("menu", ["set"]),
		navMenu() {
			let children = [];
			//console.log(this.permissions);
			for (let i = 0; i < this.$options.nav[0]._children.length; i++) {
				const el = this.$options.nav[0]._children[i];
				if (guard(el.permission)) {
					if (el.items) {
						const elItems = JSON.parse(JSON.stringify(el.items));
						el.items = [];

						for (let j = 0; j < elItems.length; j++) {
							const item = elItems[j];
							if (guard(item.permission)) {
								el.items.push(item);
							}
						}
					}

					children.push(el);
				}
			}

			return [
				{
					_name: "CSidebarNav",
					_children: children,
				},
			];
		},
	},
};
</script>
