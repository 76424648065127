export default [
	{
		_name: "CSidebarNav",
		_children: [
			{
				_name: "CSidebarNavItem",
				name: "Dashboard",
				to: "/dashboard",
				permission: "dashboard_access",
				icon: "cil-speedometer",
				badge: {
					color: "primary",
					text: "NEW",
				},
			},
			{
				_name: "CSidebarNavItem",
				name: "User",
				to: "/user",
				permission: "user_access",
				icon: "cil-speedometer",
			},
			{
				_name: "CSidebarNavDropdown",
				name: "ACL",
				route: "/role",
				permission: "acl_access",
				icon: "cil-puzzle",
				items: [
					{
						name: "Role",
						permission: "acl_access",
						to: "/role",
					},
					{
						name: "Access",
						permission: "acl_access",
						to: "/access",
					},
					{
						name: "Permission",
						permission: "acl_access",
						to: "/permission",
					},
				],
			},

			{
				_name: "CSidebarNavDropdown",
				name: "Masters",
				route: "master/product",
				permission: "master_access",
				icon: "cil-puzzle",
				items: [
					{
						name: "Category",
						permission: "category_access",
						to: "/master/category",
					},
					{
						name: "Product",
						permission: "product_access",
						to: "/master/product",
					},
				],
			},
			{
				_name: "CSidebarNavItem",
				name: "Product",
				to: "/product",
				permission: "myinterest_access",
				icon: "cil-speedometer",
				_class: "bg-success text-white",
			},
			{
				_name: "CSidebarNavItem",
				name: "Campaign",
				to: "/campaign",
				permission: "campaign_access",
				icon: "cil-speedometer",
				_class: "bg-success text-white",
			},
			{
				_name: "CSidebarNavItem",
				name: "My Interest",
				to: "/myinterest",
				permission: "myinterest_access",
				icon: "cil-speedometer",
				_class: "bg-success text-white",
			},
		],
	},
];
